<template>
 <data-table
        :data="dataset"
        :loading="loading"
        :buttons="buttons"
        :header="header"
        :responsive="true"
        class="table-sm-font"
        :auto-update="false"
        :lang="lang"
        :paging="true"
        :actions="true"
        @action="onAction"
    />
</template>

<script>
import DataTableBaseVue from '../../../../../../../Components/DataTableBase.vue'
export default {
    name: 'ExportStateTable',
    extends: DataTableBaseVue,
    computed: {
        header () {
            return [
                {
                    text: this.$t('clientSessions.detail.table.stepName'),
                    data: 'stepName',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('clientSessions.detail.table.itemIdentifier'),
                    data: 'itemIdentifier',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('clientSessions.detail.table.errorMessage'),
                    data: 'errorMessage',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('clientSessions.detail.table.skipped'),
                    data: 'skipped',
                    filterable: true,
                    sortable: true,
                    format: (input) => {
                        return input ? this.$t('app.yes') : this.$t('app.no')
                    }
                },
                {
                    text: this.$t('clientSessions.detail.table.success'),
                    data: 'success',
                    filterable: true,
                    sortable: true,
                    format: (input) => {
                        return input ? this.$t('app.yes') : this.$t('app.no')
                    }
                },
                {
                    text: this.$t('clientSessions.detail.table.internalStateJson'),
                    data: 'internalStateJson',
                    filterable: true,
                    sortable: true
                },
                {
                    text: this.$t('clientSessions.detail.table.lastChangedAt'),
                    data: 'lastChangedAt',
                    filterable: true,
                    sortable: true,
                    format (value) {
                        return (value) ? new Date(value).toLocaleString() : value
                    }
                }
            ]
        }
    }

}
</script>
