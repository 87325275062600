<template>
    <b-modal
        id="realOwnerModal"
        size="xl"
        hide-footer
        >
        <template #modal-title>
            {{ $t('clientSessions.detail.realOwner.modal.heading') }}
        </template>
        <real-owner
            :real-owner="realOwner"
            :statutory-types="statutoryTypes"
        />
    </b-modal>
</template>

<script>
import RealOwner from './RealOwner.vue'

export default {
    name: 'RealOwnerModal',
    props: {
        realOwner: {
            type: Object,
            required: true
        },
        statutoryTypes: {
            type: Array,
            reqired: true
        }
    },
    components: {
        RealOwner
    }
}
</script>
