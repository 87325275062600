<template>
    <div>
        <h4 class="mb-2">{{ $t('basicData.headings.otherInfo') }}</h4>
        <detail-item
            :title="$t('basicData.email')"
            :content="basicData.email"
        />
        <detail-item
            :title="$t('basicData.phone')"
            :content="basicData.phone"
        />
        <detail-item
            :title="$t('basicData.phonePassword')"
            :content="basicData.phonePassword"
        />

        <detail-item
            :title="$t('basicData.countryOrigin')"
            :content="basicData.countryOrigin"
        />

        <basic-data-address
            :basic-data="basicData || {}"
        />

        <basic-data-post-address
            :basic-data="basicData || {}"
        />
    </div>
</template>

<script>
import BasicDataAddress from './BasicDataAddress.vue'
import BasicDataPostAddress from './BasicDataPostAddress.vue'
export default {
    name: 'BasicDataContactInfo',
    props: {
        basicData: {
            type: Object,
            required: true
        }
    },
    components: {
        BasicDataAddress,
        BasicDataPostAddress
    }
}
</script>
