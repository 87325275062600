<template>
    <div
        v-if="basicData.postAddressActive"
    >
        <h4 class="mb-2">{{ $t('basicData.headings.postAddress') }}</h4>
        <detail-item
            :title="$t('basicData.postAddressName')"
            :content="basicData.postAddressName"
        />
        <detail-item
            :title="$t('clientSessions.detail.address.fullAddress')"
            :content="`${basicData.postAddressStreet}, ${basicData.postAddressCity}, ${basicData.postAddressZip}, ${$t(`countries.${basicData.postAddressCountry}`)}`"
        />
    </div>
</template>

<script>
export default {
    name: 'BasicDataPostAddress',
    props: {
        basicData: {
            type: Object,
            required: true
        }
    }
}
</script>
