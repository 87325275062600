<template>
    <div
        class="list-group-item d-flex align-items-center align-content-center mw-100"
    >
        <div class="d-flex w-100 mw-100 align-items-center align-content-center">
            <div class="mr-3 text-muted">
                <b-icon :icon="getIcon(file.mimeType)" font-scale="2" />
            </div>
            <div class="w-100">
                <p class="mb-0">
                    <span class="d-inline-block text-truncate">
                        {{file.filename}}
                    </span>
                </p>
                <p class="mb-0 text-muted">
                    <small>{{ renderSize(file.size) }}</small>
                </p>
            </div>
        </div>
        <b-btn
            size="sm"
            variant="primary"
            @click.prevent="onDownload()"
        >
            <b-icon icon="download" />
        </b-btn>
    </div>
</template>

<script>
function fileSizeSI (a, b, c, d, e) {
    // eslint-disable-next-line no-return-assign
    return (b = Math, c = b.log, d = 1e3, e = c(a) / c(d) | 0, a / b.pow(d, e)).toFixed(2) +
 ' ' + (e ? 'kMGTPEZY'[--e] + 'B' : 'Bytes')
}

export default {
    name: 'UploadItem',
    props: {
        file: {
            type: Object,
            required: true
        }
    },
    methods: {
        getIcon (mimeType) {
            switch (mimeType) {
                case 'application/msword':
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/vnd.oasis.opendocument.text':
                    return 'file-spreadsheet'
                case 'application/vnd.oasis.opendocument.spreadsheet':
                case 'application/vnd.ms-excel':
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    return 'file-text'
                default:
                    return 'file-post'
            }
        },
        onDownload () {
            this.$emit('download', this.file._id)
        },
        renderSize (fileSize) {
            return fileSizeSI(fileSize)
        }
    }
}
</script>
