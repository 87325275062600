<template>
    <div>
        <h4 class="mb-2">{{ $t('legalInfo.subheading') }}</h4>
        <detail-item
            :title="$t('legalInfo.politician')"
            :content="legalInfo.politician ? $t('app.yes') : $t('app.no')"
        />
        <detail-item
            :title="$t('legalInfo.criminalActivity')"
            :content="legalInfo.criminalActivity ? $t('app.yes') : $t('app.no')"
        />
        <detail-item
            :title="$t('legalInfo.sanctionedBusiness')"
            :content="legalInfo.sanctionedBusiness ? $t('app.yes') : $t('app.no')"
        />
    </div>
</template>
<script>
export default {
    name: 'LegalInfoOther',
    props: {
        legalInfo: {
            type: Object,
            required: true
        }
    }
}
</script>
