<template>
    <div>
        <h4 class="mb-3">{{ $t('clientSessions.detail.uploads.heading') }}</h4>
        <upload-item
            v-for="file in uploads"
            :key="file._id"
            :file="file"
            @download="onDownload"
        />
        <p
            v-if="uploads.length === 0"
            class="alert alert-primary"
        >
            {{ $t('noUploads') }}
        </p>
    </div>
</template>

<script>
import UploadItem from './UploadItem.vue'
export default {
    name: 'UploadsList',
    components: {
        UploadItem
    },
    props: {
        uploads: {
            type: Array,
            required: true
        }
    },
    computed: {
        sessionId () {
            return this.$route.params.sessionId
        }
    },
    methods: {
        async onDownload (fileId) {
            try {
                await this.$api.sessions.downloadFile(this.sessionId, fileId)
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.cannotDownloadFile'))
            }
        }
    }
}
</script>
