<template>
    <b-form
        @submit.prevent="onSubmit"
    >
        <form-input-select
            :id="'sessionChangeState.states'"
            v-model="state"
            :options="states"
            :validation="$v.states"
            :render-as-group="true"
        >
            <b-btn
                type="submit"
                variant="primary"
                size="sm"
            >
                {{$t('clientSessions.detail.exportState.form.submit')}}
            </b-btn>
        </form-input-select>
    </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
    name: 'SessionChangeStateForm',
    props: {
        currentState: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            state: this.currentState || null
        }
    },
    watch: {
        currentState () {
            this.$nextTick(() => {
                this.state = this.currentState || null
            })
        }
    },
    computed: {
        states () {
            const states = ['abandon', 'sent', 'process', 'error', 'done', 'edit']
            return states.map(item => {
                return {
                    value: item,
                    text: this.$t(`clientSessions.state.${item}`)
                }
            })
        }
    },
    methods: {
        onSubmit () {
            this.$v.$reset()
            this.$nextTick(() => {
                if (this.$v.$invalid) {
                    this.$notify.error(this.$t('errors.someDataIsMissing'))
                    this.$v.$touch()
                } else {
                    this.$emit('submit', JSON.parse(JSON.stringify(this.$data)))
                }
            })
        }
    },
    validations: {
        states: {
            required
        }
    }
}
</script>
