<template>
    <div class="d-flex flex-column">
        <h4 class="mb-2">{{ $t('clientSessions.detail.legalInfo.heading') }}</h4>
        <detail-item
            v-if="legalInfo.socialBenefits"
            :title="`${$t('legalInfo.socialBenefits')}: ${$t('app.yes')}`"
            :content="`${$t('clientSessions.detail.legalInfo.moneyOrigin.desc')} ${legalInfo.socialBenefitsField || ''}`"
        />
        <detail-item
            v-if="legalInfo.wage"
            :title="`${$t('legalInfo.wage')}: ${$t('app.yes')}`"
            :content="`${$t('clientSessions.detail.legalInfo.moneyOrigin.desc')} ${legalInfo.employment}`"
        />
        <detail-item
            v-if="legalInfo.business"
            :title="`${$t('legalInfo.business')}: ${$t('app.yes')}`"
            :content="`${$t('clientSessions.detail.legalInfo.moneyOrigin.desc')} ${legalInfo.businessField}`"
        />
        <detail-item
            v-if="legalInfo.capital"
            :title="`${$t('legalInfo.capital')}: ${$t('app.yes')}`"
            :content="`${$t('clientSessions.detail.legalInfo.moneyOrigin.desc')} ${legalInfo.capitalResources}`"
        />
        <detail-item
            v-if="legalInfo.other"
            :title="`${$t('legalInfo.other')}: ${$t('app.yes')}`"
            :content="`${$t('clientSessions.detail.legalInfo.moneyOrigin.desc')} ${legalInfo.otherResources}`"
        />
        <uploads-list
            v-if="moneyOriginUploads.length > 0"
            class="mt-auto"
            :uploads="moneyOriginUploads"
        />
    </div>
</template>
<script>
import UploadsList from './UploadsList.vue'

export default {
    name: 'LegalInfoMoneyOrigin',
    components: {
        UploadsList
    },
    props: {
        legalInfo: {
            type: Object,
            required: true
        },
        uploads: {
            type: Array,
            required: true
        }
    },
    computed: {
        moneyOriginUploads () {
            const metaFields = ['capital', 'other']
            const moneyOriginUploads = (this.legalInfo?.uploads ?? []).filter(item => metaFields.includes(item.meta)).map(item => (item.upload ?? item))
            return this.uploads.filter(upload => moneyOriginUploads.includes(upload._id))
        }
    }
}
</script>
