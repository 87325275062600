<template>
    <div>
        <h4 class="mb-2">{{$t('legalInfo.reasonAndAmount')}}</h4>
        <detail-item
            :title="$t('clientSessions.detail.legalInfo.reasonAndAmount.reason')"
            :content="legalInfo.reason"
        />
        <detail-item
            :title="$t('clientSessions.detail.legalInfo.reasonAndAmount.transactionsAmount')"
            :content="legalInfo.transactionsAmount"
        />
        <detail-item
            :title="$t('clientSessions.detail.legalInfo.reasonAndAmount.transactionsInterval')"
            :content="transactionsInterval(legalInfo.transactionsInterval)"
        />
        <uploads-list
            v-if="reasonsUploads.length > 0"
            class="mt-3"
            :uploads="reasonsUploads"
        />
    </div>
</template>
<script>
import UploadsList from './UploadsList.vue'
export default {
    name: 'LegaInfoReasonAndAmount',
    components: {
        UploadsList
    },
    props: {
        legalInfo: {
            type: Object,
            required: true
        },
        uploads: {
            type: Array,
            required: true
        }
    },
    computed: {
        reasonsUploads () {
            const reasonsUploads = (this.legalInfo?.uploads ?? []).filter(item => `${item.meta}`.startsWith('reasons')).map(item => (item.upload ?? item))
            return this.uploads.filter(upload => reasonsUploads.includes(upload._id))
        }
    },
    methods: {
        transactionsInterval (interval) {
            switch (interval) {
                case 'daily':
                    return this.$t('legalInfo.intervalDaily')
                case 'monthly':
                    return this.$t('legalInfo.intervalMonthly')
                case 'quarterly':
                    return this.$t('legalInfo.intervalQuarterly')
                case 'halfyearly':
                    return this.$t('legalInfo.intervalHalfyearly')
                case 'yearly':
                    return this.$t('legalInfo.intervalYearly')
                case 'less':
                    return this.$t('legalInfo.intervalLess')
                default:
                    return this.$t('app.unknown')
            }
        }
    }
}
</script>
