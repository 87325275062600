<template>
    <div>
        <h4 class="mb-2">{{ $t('clientSessions.detail.clientInfo.personalInfo') }}</h4>
        <div class="row mb-2">
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('clientSessions.detail.companyPerson.fullName')"
                :content="`${realOwner.name} ${realOwner.surname}`"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('basicData.birthDate')"
                :content="new Date(realOwner.birthDate).toLocaleDateString()"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('basicData.countryOrigin')"
                :content="realOwner.countryOrigin"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('companyOwnersForm.citizenship')"
                :content="$t(`countries.${realOwner.citizenship}`)"
            />
            <detail-item
                class="col-12 col-lg-4"
                :title="$t('clientSessions.detail.clientInfo.politician')"
                :content="realOwner.politician ? $t('app.yes') : $t('app.no')"
            />

            <detail-item
                class="col-12 col-lg-4"
                :title="$t('clientSessions.detail.clientInfo.isStatutory')"
                :content="realOwner.isStatutory ? statutoryType : $t('app.no')"
            />

            <detail-item
                class="col-12 col-lg-4"
                :title="$t('realOwners.form.relationshipDetail')"
                :content="`${realOwner?.relationshipDetail ?? 'Není skutečný majitel'}`"
            />
        </div>

        <h4 class="mb-2">{{ $t('clientSessions.detail.address.heading') }}</h4>
        <detail-item
            class="mb-4"
            :title="$t('clientSessions.detail.address.fullAddress')"
            :content="`${realOwner.addressStreet}, ${realOwner.addressCity}, ${realOwner.addressZip}, ${$t(`countries.${realOwner.addressCountry}`)}`"
        />
    </div>
</template>
<script>
export default {
    name: 'RealOwner',
    props: {
        realOwner: {
            type: Object,
            required: true
        },
        statutoryTypes: {
            type: Array,
            required: true
        }
    },
    computed: {
        statutoryType () {
            return this.statutoryTypes.find(item => item.value === this.realOwner.statutoryType).text
        }
    }
}
</script>
