<template>
    <div>
        <h4 class="mb-2">{{ $t('clientSessions.detail.address.heading') }}</h4>
        <detail-item
            :title="$t('clientSessions.detail.address.fullAddress')"
            :content="`${basicData.addressStreet}, ${basicData.addressCity}, ${basicData.addressZip}, ${$t(`countries.${basicData.addressCountry}`)}`"
        />
    </div>
</template>

<script>
export default {
    name: 'BasicDataAddress',
    props: {
        basicData: {
            type: Object,
            required: true
        }
    }
}
</script>
