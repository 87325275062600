<template>
    <div>
        <h4 class="mb-2">{{ clientType === 'PO' ? $t('clientSessions.detail.clientInfo.company') : $t('clientSessions.detail.clientInfo.personalInfo') }}</h4>
        <div class="row">
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('clientSessions.detail.clientInfo.clientType')"
                :content="$t(`clientSessions.clientType.${clientType}`)"
            />
            <detail-item
                class="col-12 col-lg-6"
                :title="clientType === 'PO' ? $t('clientSessions.detail.clientInfo.companyName') : $t('clientSessions.detail.clientInfo.clientName')"
                :content="clientName"
            />
        </div>
        <div
            class="row"
            v-if="clientType !== 'PO'"
        >
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('basicData.rc')"
                :content="basicData.noRc ? $t('clientSessions.detail.clientInfo.noRc') : basicData.rc"
            />
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('basicData.birthDate')"
                :content="new Date(basicData.birthDate).toLocaleDateString()"
            />
        </div>
        <div
            class="row"
            v-if="clientType !== 'FO'"
        >
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('basicData.ic')"
                :content="basicData.ic"
            />
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('basicData.vat')"
                :content="basicData.vat"
            />
        </div>

        <div class="row">
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('basicData.domicile')"
                :content="$t(`countries.${basicData.domicile}`)"
            />
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('clientSessions.detail.clientInfo.politician')"
                :content="basicData.politician ? $t('app.yes') : $t('app.no')"
            />
        </div>
        <div
            class="row"
            v-if="clientType !== 'FO'"
        >
            <detail-item
                class="col-12 col-lg-6"
                :title="clientType === 'PO' ? $t('basicData.companyScopePO') : $t('basicData.companyScopeOS')"
                :content="basicData.companyScope"
            />
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('basicData.companyRealScope')"
                :content="basicData.companyRealScope"
            />
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('basicData.companyForm')"
                :content="basicData.companyForm"
            />
            <detail-item
                class="col-12 col-lg-6"
                :title="$t('basicData.companyRiskActivity')"
                :content="basicData.companyRiskActivity"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BasicDataClientInfo',
    props: {
        basicData: {
            type: Object,
            required: true
        },
        clientType: {
            type: String,
            required: true
        }
    },
    computed: {
        clientName () {
            return (this.clientType === 'PO' ? `${this.basicData.companyName}` : `${this.basicData.title || ''} ${this.basicData.name} ${this.basicData.surname}`)
        }
    }
}
</script>
